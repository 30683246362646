<template>
  <div class="row m-0 p-0 cta ctaBody">
    <div class="col-md-6 ctaColumnBGL text-center">
      <div class="ctaText">
        <a href="https://governor.vermont.gov/press-release/governor-phil-scott-appoints-john-kascenska-vermont-house-representatives" style="color: black;">March 7, 2022 Governor Phil Scott Press Release</a>
        <div class="quoteText text-center">"John has demonstrated a commitment to public service and has a wide range of experience that will be valuable in the House of Representatives – from his time as an educator to being a small business owner,” said Governor Scott. “I’m confident John will have a positive impact on the Legislature’s work and represent the people of his district well.”</div>
      </div>
    </div>
    <div class="col-md-6 ctaColumnR">
    </div>
  </div>
</template>

<script>
export default {
  name: "CallToAction"
}
</script>

<style scoped>
.cta{
  padding: 0;
  background: gray; /* older browsers */
  background: rgba(150,0,0,0.7); /* newer browsers */
}
.ctaColumnBGL {
  min-height: 450px;
  text-align: left;
  color: white;
  padding: 7vh 2vw;
}
.ctaColumnR {
  background: url("../assets/GOVERNOR SCOTT PHOTO MAY 11 2022.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
}
.ctaText{
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-style: italic;
}
</style>
