<template>
  <div class="w-auto m-auto">
    <img src="../assets/Picture1.png">
  </div>
</template>

<script>
export default {
  name: "MagLogo"
}
</script>

<style scoped>
.maglogo{
  max-width: 15vw;
}
img{
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>
